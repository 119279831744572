
import { defineComponent, onMounted } from 'vue';
import { getOrders } from './composables/Orders';
import {
  OrderConfirmDialog,
  OrderInfoDialog,
  SearchOptions,
  OrderTables,
} from './components';

export default defineComponent({
  components: {
    SearchOptions,
    OrderTables,
    OrderConfirmDialog,
    OrderInfoDialog,
  },
  setup() {
    onMounted(() => {
      getOrders();
    });

    return {};
  },
});
