
import {
  defineComponent, reactive,
} from 'vue';
import { ElMessageBox, ElMessage } from 'element-plus';
import { OrderStatus } from '../page.config';
import {
  orderState, getOrders, setCurrentOrder, cancelOrderSubmit,
} from '../composables/Orders';

export default defineComponent({
  setup() {
    /**
     * 设置当前信息，并且显示弹出层
     * @param item
     */
    const handleShowCarInfoDialog = (item: any) => {
      setCurrentOrder(item);
      orderState.orderInfoDialogVisible = true;
    };

    const handleShowConfirmDialog = (item: any) => {
      setCurrentOrder(item);
      orderState.orderConfirmDialogVisible = true;
    };

    const handleCloseOrder = async (item: any) => {
      setCurrentOrder(item);
      try {
        await ElMessageBox.confirm(
          '您确定该订单已退订吗？该确认一旦操作不可逆，请谨慎操作！',
          '确认退订',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          },
        );
        // 确认操作，继续逻辑
        const orderId = item?.order_no;
        const { code, message } = await cancelOrderSubmit(orderId);

        if (code !== 0) {
          ElMessage.error(message);
          return;
        }

        ElMessage({
          message: '退订订单操作成功',
          type: 'success',
        });
      } catch (e) {
        if (e === 'cancel') {
          console.log('取消操作');
        }
      }
    };

    const handlePageChange = (page: number) => {
      getOrders({ page });
    };

    return {
      orderState,
      OrderStatus: reactive(OrderStatus),
      handleShowCarInfoDialog,
      handleShowConfirmDialog,
      handleCloseOrder,
      handlePageChange,
    };
  },
});
