
import {
  defineComponent,
} from 'vue';
import { orderState } from '../composables/Orders';

export default defineComponent({
  setup() {
    return {
      orderState,
    };
  },
});
