
import { defineComponent } from 'vue';
import { OrderStatusDict, OrderStatus } from '../page.config';
import { handleResetSearchOptions, getOrders, orderState } from '../composables/Orders';

export default defineComponent({
  setup() {
    const handleSearchBtnClick = () => {
      getOrders({ page: 1 });
    };

    const orderStatusOptions = Object.entries(OrderStatusDict)
      .filter(([value]) => +value as OrderStatus !== OrderStatus.UN_KNOWN)
      .map(([value, label]) => ({ value, label }));

    return {
      orderState,
      orderStatusOptions,
      handleSearchBtnClick,
      handleResetSearchOptions,
    };
  },
});
