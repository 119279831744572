
import {
  defineComponent, reactive, toRaw,
} from 'vue';
import { ElMessage } from 'element-plus';
import { orderState, getOrders } from '../composables/Orders';
import { confirmOrderSubmit } from '../service.config';

export default defineComponent({
  setup() {
    // 确认订单提交参数
    const confirmForm = reactive({
      expect_deliver_at: '',
    });

    const handleOrderConfirmSubmit = async () => {
      if (!confirmForm.expect_deliver_at) {
        ElMessage.error('请选择预计交车时间');
        return;
      }

      const orderNo = (orderState.currentOrder as any)?.order_no;

      const { code, message } = await confirmOrderSubmit(orderNo, toRaw(confirmForm));

      if (code !== 0) {
        ElMessage.error(message);
        return;
      }

      ElMessage({
        message: '确认订单操作成功',
        type: 'success',
      });

      orderState.orderConfirmDialogVisible = false;
      confirmForm.expect_deliver_at = '';
      // 刷新数据
      await getOrders({ page: 1 });
    };

    return {
      orderState,
      confirmForm,
      handleOrderConfirmSubmit,
    };
  },
});
