import { rest, ResponseData } from '@/ajax';

interface GetOrderListParams {
  page: number,
  count: number,
  order_no?: number | string,
  buyer_name?: string,
  buyer_mobile_number?: string,
  seller_mobile_number?: string,
  order_status?: number | string,
}

export async function getOrderList(params: GetOrderListParams): Promise<ResponseData<any>> {
  return rest.order.getOrderList(params);
}

export async function confirmOrderSubmit(orderNo: number, params: { expect_deliver_at: string }) {
  return rest.order.confirmOrderSubmit(orderNo, params);
}

export async function cancelOrderSubmit(orderNo: number) {
  return rest.order.cancelOrderSubmit(orderNo);
}

export default {};
