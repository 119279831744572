import { reactive, ref, toRaw } from 'vue';
import { ElMessage } from 'element-plus';
import Big from 'big.js';
import {
  OrderStatus, OrderStatusDict, SourceType, SourceTypeDict,
} from '@/modules/order/page.config';
import { getOrderList, cancelOrderSubmit as cancelOrder } from '../service.config';

// 订单状态
export const orderState = reactive({
  searchOptions: {
    page: 1,
    count: 10,
    order_no: '',
    buyer_name: '',
    buyer_mobile_number: '',
    seller_mobile_number: '',
    order_status: '',
  },
  totalCount: 0,
  tableData: [],
  currentOrder: {},
  orderConfirmDialogVisible: false,
  orderInfoDialogVisible: false,
});

export function handleResetSearchOptions() {
  const { searchOptions } = orderState;
  searchOptions.page = 1;
  searchOptions.count = 10;
  searchOptions.order_no = '';
  searchOptions.buyer_name = '';
  searchOptions.buyer_mobile_number = '';
  searchOptions.seller_mobile_number = '';
  searchOptions.order_status = '';
}

export async function getOrders(params?: any) {
  const queryParams = {
    ...toRaw(orderState.searchOptions),
    ...params,
  };

  // 重新赋值
  orderState.searchOptions = reactive(queryParams);

  const {
    code,
    data: {
      orders = [],
      total_count: totalCount,
    } = {},
    message,
  } = await getOrderList(queryParams);
  //
  if (code !== 0) {
    ElMessage.error(message);
    return;
  }
  //
  const formatData = orders
    .map((item: any) => ({
      ...item,
      status_name: OrderStatusDict[item.status as OrderStatus],
      car_info: {
        ...item.car_info,
        deposit_text: new Big(item.car_info?.deposit).div(100).toFixed(2),
        price_text: new Big(item.car_info?.price).div(100).toFixed(2),
      },
      user: {
        ...item.user,
        source_origin: SourceTypeDict[item.user?.source_type as SourceType],
      },
    }));

  orderState.tableData = reactive(formatData);
  orderState.totalCount = ref(totalCount);
}

export function setCurrentOrder(orderInfo: any) {
  orderState.currentOrder = reactive(orderInfo);
}

export async function cancelOrderSubmit(orderId: number) {
  const { code, message } = await cancelOrder(orderId);

  if (code === 0) {
    getOrders({ page: 1 });
  }

  return { code, message };
}

export default {};
