import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6057e2bd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "carInfo-item" }
const _hoisted_2 = { class: "carInfo-item" }
const _hoisted_3 = { class: "carInfo-item" }
const _hoisted_4 = { class: "carInfo-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.orderState.orderInfoDialogVisible,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.orderState.orderInfoDialogVisible) = $event)),
    title: "车辆配置",
    width: "377px",
    center: "",
    "close-on-press-escape": false
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.orderState.currentOrder?.car_info?.car_model) + " " + _toDisplayString(_ctx.orderState.currentOrder?.car_info?.car_version) + " （¥" + _toDisplayString(_ctx.orderState.currentOrder?.car_info?.price_text) + "） ", 1),
        _createElementVNode("p", _hoisted_2, "外观: " + _toDisplayString(_ctx.orderState.currentOrder?.car_info?.exterior_color), 1),
        _createElementVNode("p", _hoisted_3, " 内饰: " + _toDisplayString(_ctx.orderState.currentOrder?.car_info?.interior_color) + " (" + _toDisplayString(_ctx.orderState.currentOrder?.car_info?.interior_material) + ") ", 1),
        _createElementVNode("p", _hoisted_4, "选装: " + _toDisplayString(_ctx.orderState.currentOrder?.car_info?.charging_option), 1)
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}